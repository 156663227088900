import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <>
      <div className="footer">
        <p>Fizjotrener &copy; 2022</p>
      </div>
    </>
  );
}

export default Footer;
