import React from "react";
import "./Pricing.css";
import PricingCard from "./PricingCard.js";
import List from "./List.js";

const items1 = [
  "min. 1 godzina",
  "trening funkcjonalny, siłowy, obwodowy lub medyczny",
  "trening na siłowni, w plenerze, w domu lub w biurze",
  "możliwy trening w parze",
];
const items2 = [
  "min. 1 godzina",
  "trening funkcjonalny, siłowy, obwodowy lub medyczny",
  "trening na siłowni, w plenerze, w domu lub w biurze",
  "możliwy trening w parze",
];
const items3 = [
  "min. 1 godzina",
  "trening funkcjonalny, siłowy, obwodowy lub medyczny",
  "trening na siłowni, w plenerze, w domu lub w biurze",
  "możliwy trening w parze",
];
const items4 = [
  "masaż relaksacyjny jako technika manualna pozwala na zniwelowanie nadmiernego napięcia mięśni, poprawienie krążenia krwi oraz kondycji skóry",
  "wskazany jest przy długotrwałym napięciu mięśniowo-nerwowym, które może powodować uczucie przemęczenia, brak energii i chęci do działania, a nawet spadek koncentracji",
];
const items5 = [
  "masaż leczniczy wykonywany jest z większą siłą nacisku na tkanki",
  "ta forma masażu przede wszystkim skupia się na opracowaniu dysfunkcji i bólach kręgosłupa, stawów, mięśni, a także wspomaganiu leczenia pooperacyjnego, urazowego oraz kontuzji",
  "podczas masażu wykorzystywane są techniki terapii manualnej oraz narzędziowej",
];


function Pricing() {
  return (
    <>
      <div id="offer" className="offer_title">
        <div>Oferta</div>
      </div>
      <div className="pricing">
        <PricingCard
          bundle="single"
          number="1x"
          title="Trening personalny"
          list={<List items={items1} />}
          price="120 PLN"
        />
        <PricingCard
          bundle="pakiet"
          number="5x"
          title="Trening personalny"
          list={<List items={items2} />}
          price="550 PLN"
        />
        <PricingCard
          bundle="pakiet"
          number="10x"
          title="Trening personalny / miesiąc"
          list={<List items={items3} />}
          price="1000 PLN"
        />
        <PricingCard
          bundle="single"
          number="30min"
          title="Masaż rekalsacyjny"
          list={<List items={items4} />}
          price="80 PLN"
        />
        <PricingCard
          bundle="single"
          number="45min"
          title="Masaż rekalsacyjny"
          list={<List items={items4} />}
          price="100 PLN"
        />
        <PricingCard
          bundle="single"
          number="60min"
          title="Masaż rekalsacyjny"
          list={<List items={items4} />}
          price="120 PLN"
        />
        <PricingCard
          bundle="single"
          number="30min"
          title="Masaż leczniczy"
          list={<List items={items5} />}
          price="80 PLN"
        />
        <PricingCard
          bundle="single"
          number="45min"
          title="Masaż leczniczy"
          list={<List items={items5} />}
          price="100 PLN"
        />
        <PricingCard
          bundle="single"
          number="60min"
          title="Masaż leczniczy"
          list={<List items={items5} />}
          price="120 PLN"
        />
      </div>
      
    </>
  );
}

export default Pricing;
